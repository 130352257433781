<template>
  <main>
  <div>
    <b-row class="auth-inner mainrowtxt justify-content-center">
        <!-- Quote -->
        <b-col
        md="10"
        lg="6"
        xl="5"
        class="d-flex align-items-center border-5"
        >
        <b-col
            sm="11"
            md="10"
            lg="12"
            class="px-xl-2 mx-auto"
        >
            <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
            >
            <h3 class="sft_title">{{$t('message.main_sft_title')}}</h3>
            <span class="secondary_c">{{$t('message.main_sft_text')}}</span>
            </b-card-title>

            <!-- form -->
            <validation-observer
            ref="loginForm"
            #default="{invalid}"
            >
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
            >
                <b-button
                href="/forms/quotation"
                type="submit"
                variant="secondary"
                block
                :disabled="invalid"
                >
                {{$t('message.get_quote')}}
                </b-button>
                <div class="divider my-2">
                  <div class="divider-text">
                    {{$t('message.or')}}
                  </div>
                </div>
                <b-button
                href="/payments"
                type="submit"
                variant="success"
                block
                :disabled="invalid"
                >
                {{$t('message.payment_btn')}}
                </b-button>
            </b-form>
            </validation-observer>
        </b-col>
        </b-col>
        <!-- Quote-->

        <!-- Right Img-->
        <b-col
        lg="6"
        xl="7"
        class="d-none d-lg-flex align-items-center"
        >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-2">
            <b-img
            fluid
            :src="imgUrl"
            alt="Safetravel"
            />
        </div>
        </b-col>
        <!-- Right Img-->
    </b-row>
    <b-row>
        <b-col
        cols="12"
        class="text-center pt-4 pb-1"
        >
          <h2 class="tailormade_title">{{$t('message.tailor_made')}}</h2>
        </b-col>
        <!-- Products Info Img-->
        <b-col
        lg="6"
        class="d-none d-lg-flex pt-2"
        >
            <div class="w-100 d-lg-flex align-items-start justify-content-center stickytopparent">
                <b-img
                fluid
                :src="travelImg"
                alt="Safetravel"
                class="stickytop"
                />
            </div>
        </b-col>
        <!-- Products Info Img-->

        <!-- Products Info Text-->
        <b-col
        lg="6"
        class="align-items-center bg-white pt-2 border-5 tailormadecolor"
        >
            <div>
                <h2 class="tailormade_txt">{{$t('message.based_needs')}}</h2>
                <ul class="listtypedlist">
                    <li>{{$t('message.ins_person')}}</li>
                    <li>{{$t('message.ins_day')}}</li>
                    <li>{{$t('message.ins_ticket')}}</li>
                    <li>{{$t('message.ins_need')}}</li>
                </ul>
            </div>
            <div>
                <h2 class="tailormade_txt">{{$t('message.plc_trip')}}</h2>
                <ul class="checklisttype mb-0">
                    <li><font-awesome-icon class="dbcheck" icon="fa-solid fa-check-double" size="lg" /><span class="spanboldchecklist">Single trip:</span> {{$t('message.single_trp_text')}}</li>
                    <li><font-awesome-icon class="dbcheck" icon="fa-solid fa-check-double" size="lg" /><span class="spanboldchecklist">Annual multi trip:</span> {{$t('message.annual_trp_text')}}</li>
                    <li><font-awesome-icon class="dbcheck" icon="fa-solid fa-check-double" size="lg" /><span class="spanboldchecklist">Students Insurance:</span> {{$t('message.student_trp_text')}}</li>
                    <li><font-awesome-icon class="dbcheck" icon="fa-solid fa-check-double" size="lg" /><span class="spanboldchecklist">Business Travel Insurance:</span> {{$t('message.bussiness_trp_text')}}</li>
                    <li><font-awesome-icon class="dbcheck" icon="fa-solid fa-check-double" size="lg" /><span class="spanboldchecklist">Visa Travel Insurance:</span> {{$t('message.visa_trp_text')}}</li>
                    <li><font-awesome-icon class="dbcheck" icon="fa-solid fa-check-double" size="lg" /><span class="spanboldchecklist">{{$t('message.covid_cover')}}</span> 
                        <span class="d-block">{{$t('message.covid_incld')}}</span>
                        <span class="d-block">{{$t('message.covid_hlth')}}</span>
                        <span class="d-block">{{$t('message.monitring')}}</span>
                        <span class="d-block mt-2">{{$t('message.what_cover')}}</span>
                        <ul class="mt-1" style="list-style-type:none;">
                            <li><font-awesome-icon class="dbcheck" icon="fa-solid fa-check-double" size="lg" />{{$t('message.emergmedi')}}</li>
                            <li><font-awesome-icon class="dbcheck" icon="fa-solid fa-check-double" size="lg" />{{$t('message.domicile')}}</li>
                        </ul>
                        <span class="d-block">{{$t('message.addnt_transf')}}</span>
                    </li>
              </ul>
            </div>
        </b-col>
        <!-- Products Info Text-->
    </b-row>
    <b-row>
      <b-col lg="12">
        <div class="mb-4 mt-4 dashed-background">
          <h2 class="text-center safetravelpalletedeepgreen mb-1">{{$t('message.pop_dest')}}</h2>
          <div class="container-fluid">
            <b-row class="populardestsrow">
              <b-col xl="6" lg="6" md="6" sm="6">
                <b-card 
                  title="Paris, FRA"
                  :img-src="imgFrance"
                  img-alt="France"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 19.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col xl="6" lg="6" md="6" sm="6">
                <b-card
                  title="London, GBR"
                  :img-src="imgUK"
                  img-alt="United Kingdom"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 35.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col xl="6" lg="6" md="6" sm="6">
                <b-card
                  title="Rome, ITA"
                  :img-src="imgItaly"
                  img-alt="Italy"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 28.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="6">
                <b-card
                  title="Alpes, AUT"
                  :img-src="imgAustria"
                  img-alt="Austria"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 19.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="6">
                <b-card
                  title="Barcelona, ESP"
                  :img-src="imgSpain"
                  img-alt="Spain"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 12.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="6">
                <b-card
                  title="Budapest, HUN"
                  :img-src="imgHungary"
                  img-alt="Hungary"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 35.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="6">
                <b-card
                  title="Amsterdam, NLD"
                  :img-src="imgHolland"
                  img-alt="Holland"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 23.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col xl="6" lg="6" md="6" sm="6">
                <b-card
                  title="Santorini, GRC"
                  :img-src="imgGreece"
                  img-alt="Greece"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 5.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="6">
                <b-card
                  title="New Delhi, IND"
                  :img-src="imgIndia"
                  img-alt="India"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 23.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="6">
                <b-card
                  title="Beijing, CHN"
                  :img-src="imgChina"
                  img-alt="China"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 66.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="6">
                <b-card
                  title="Male, MDV"
                  :img-src="imgMaldives"
                  img-alt="Maldives"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 54.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="6">
                <b-card
                  title="Bridgetown, BRB"
                  :img-src="imgBarbados"
                  img-alt="Barbados"
                  img-top
                  img-height="250px"
                >
                  <b-card-text>
                    <h3>{{$t('message.from_price')}} 53.99€</h3>
                  </b-card-text>
                </b-card>
              </b-col>
  
            </b-row>
          </div>
          <div class="blurred"></div>
        </div>
      </b-col>
    </b-row>
  </div>
  </main>
</template>

<script>
/* eslint-disable global-require */
// import Locale from '../components/Locale.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip, BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCard,
    // Locale
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      logo: require('@/assets/images/quotation/sft_logo.svg'),
      status: '',
      password: 'admin',
      sideImg: require('@/assets/images/pages/login-v2-dark.svg'),
      travelImg: require('@/assets/images/pages/clipartproducts.png'),
      imgFrance: require('@/assets/images/destinations/france.jpg'),
      imgUK: require('@/assets/images/destinations/united_kingdom.jpg'),
      imgItaly: require('@/assets/images/destinations/italy.jpg'),
      imgAustria: require('@/assets/images/destinations/austria.jpg'),
      imgBarbados: require('@/assets/images/destinations/barbados.jpg'),
      imgChina: require('@/assets/images/destinations/china.jpg'),
      imgSpain: require('@/assets/images/destinations/spain.jpg'),
      imgHungary: require('@/assets/images/destinations/hungarynew.jpg'),
      imgHolland: require('@/assets/images/destinations/netherlands.jpg'),
      imgGreece: require('@/assets/images/destinations/greece.jpg'),
      imgIndia: require('@/assets/images/destinations/india.jpg'),
      imgMaldives: require('@/assets/images/destinations/maldives.jpg'),
      selectedContryFrom: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            password: this.password,
          })
            .then(response => {
              const { userData } = response.data
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  })
                })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.populardestsrow img {
  object-fit: cover;
}
.populardestsrow .card {
  box-shadow: 0px 2px 10px #888888;
}
.populardestsrow .card > .card-body > .card-title, .populardestsrow .card > .card-body > .card-text > h3 {
  color: var(--foreground-color);
}
.populardestsrow .card > .card-body > .card-text > h3 {
  font-style: oblique;
  font-weight:400;
}
.img_logo {
    display: block;
    margin: 0 auto;
    max-width: 700px;
    width: 80%;
    height: auto;
}
.secondary_c {
    color: var(--foreground-color);
    font-weight: 500;
    font-size: 16px;
    .dark-layout & {
      color: var(--accent2-color);
    }
}
.sft_title {
    color: var(--accent3-color);
    font-weight: 600;
    font-size: 2em;
}
.signintxt {
    color: var(--foreground-color);
}
.tailormadecolor {
  font-weight:500;
  color: var(--foreground-color);
  .dark-layout & {
    color: var(--foreground-color);
  }
}
.tailormade_txt {
    color: var(--accent1-color);
    padding-bottom: 10px;
    font-size: 1.5rem;
    font-weight: 600;
    display: block;
    .dark-layout & {
      color: var(--accent1-color);
    }
}
.tailormade_title, .safetravelpalletedeepgreen {
    color: var(--accent1-color);
    position: relative;
    padding-bottom: 10px;
    font-size: 2.3rem;
    font-weight: 600;
}
.tailormade_title::before {
    content: "";
    position: absolute;
    bottom: 0;
    border-radius: 4px;
    margin-left: 5px;
    width: 80px;
    height: 4px;
    background: #5d8191;
}
.listtypedlist {
    list-style-type: disc;
    padding-left: 1rem;
    padding-bottom: 0.75em;
}
.listtypedlist li {
    padding-left: 0.5em;
}
.listtypedlist li::marker {
    color: #4abb9d;
    content: "►";
}
.checklisttype {
    list-style: none;
    padding: 0;
}
.checklisttype li {
    padding-left: 1.3em;
    padding-bottom: 1em;
}
.dbcheck {
    margin-left: -1.3em;
    width: 1.3em;
    color: #4abb9d;
}
.spanboldchecklist {
    color: #569495;
    font-weight: 900;
}
.stickytop {
    position: sticky;
    top: 0;
    padding-top:100px;
}
.stickytopparent {
    margin-top:-100px;
}
.border-5 {
  border-radius: 10px;
}
.destinationimg {
  height: 250px;
}
@media screen and (max-width: 992px) {
  .mainrowtxt {
    margin-top:25px;
  }
}
</style>
